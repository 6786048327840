<template>
  <div>
    <div v-if="loadingFormData" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-1">{{ $t('CATALOG_LOADING_LABEL', {catalog: $t('ORGANIZATION')})  | capitalize }}</strong>
    </div>
    <b-card bg-variant="dark" text-variant="light" v-else :title="$t('FORMS_GENERAL_DATA_TITLE') | capitalize">
      <b-card-text>
        <pre v-if="debug">{{ form }}</pre>
        <b-form @submit.prevent="save">
          <!-- rfc text input -->
          <b-form-group
              id="rfc-form-group"
              :label="$t('ORGANIZATION_RFC_LABEL') | capitalize"
              label-for="rfc-input"
              :state="state('rfc')">
            <b-form-input class="w-100 ml-1" id="rfc-input" type="text"
                          v-model="$v.form.rfc.$model"
                          :state="state('rfc')"
                          trim></b-form-input>

            <b-form-invalid-feedback id="rfc-input-feedback">
              <div v-for="error in errors('rfc')" :key="error.error">
                {{
                  $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('ORGANIZATION_RFC_LABEL')},})  | capitalize
                }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- rfc text input -->

          <!-- name text input -->
          <b-form-group
              id="name-form-group"
              :label="$t('ORGANIZATION_NAME_LABEL') | capitalize"
              label-for="name-input"
              :state="state('name')">
            <b-form-input class="w-100 ml-1" id="name-input" type="text"
                          v-model="$v.form.name.$model"
                          :state="state('name')"
                          trim></b-form-input>

            <b-form-invalid-feedback id="name-input-feedback">
              <div v-for="error in errors('name')" :key="error.error">
                {{
                  $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('ORGANIZATION_NAME_LABEL')},})  | capitalize
                }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- name text input -->

          <!-- database text input -->
          <b-form-group
              id="database-form-group"
              :label="$t('ORGANIZATION_DATABASE_LABEL') | capitalize"
              label-for="database-input"
              :state="state('database')">
            <b-form-input class="w-100 ml-1" id="database-input" type="text"
                          v-model="$v.form.database.$model"
                          :state="state('database')"
                          trim></b-form-input>

            <b-form-invalid-feedback id="database-input-feedback">
              <div v-for="error in errors('database')" :key="error.error">
                {{
                  $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('ORGANIZATION_DATABASE_LABEL')},})  | capitalize
                }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!-- database text input -->

          <b-button class="mt-2" type="submit" :disabled="saving" variant="primary">
            <b-icon v-if="saving" icon="circle-fill" animation="throb" class="mr-2"></b-icon>
            <span v-if="saving">{{ $t('FORMS_SAVING_LABEL')  | capitalize }}</span>
            <span v-else>{{ $t('FORMS_SAVE_LABEL')  | capitalize }}</span>
          </b-button>
        </b-form>
      </b-card-text>
    </b-card>

    <!-- accounts -->
    <b-card bg-variant="dark" text-variant="light" class="mt-1" :title="$t('ORGANIZATION_ACCOUNTS_TITLE') | capitalize">
      <b-card-text>
        <b-button @click="addAccount">{{ $t('FORMS_ORGANIZATION_ACCOUNTS_ADD') | capitalize }}</b-button>

        <div class="d-inline-block w-100 my-1" :key="index" v-for="(v, index) in $v.form.accounts.$each.$iter">
          <b-form-group
              class="w-40 pr-1 d-inline-block"
              id="bank-account-code-form-group"
              :label="$t('BANK_ACCOUNT_CODE_LABEL') | capitalize"
              label-for="bank-account-code-input"
              :state="state2(v, 'code')">
            <b-form-input class="w-100 ml-1" id="type-input" type="text"
                          v-model="v.code.$model"
                          :state="state2(v, 'code')"
                          trim></b-form-input>

            <b-form-invalid-feedback id="type-input-feedback">
              <div v-for="error in errors2(v, 'code')" :key="error.error">
                {{
                  $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('BANK_ACCOUNT_CODE_LABEL')},})  | capitalize
                }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
              class="w-40 pr-1 d-inline-block"
              id="bank-account-description-form-group"
              :label="$t('ORGANIZATION_ACCOUNT_TYPE_LABEL') | capitalize"
              label-for="bank-account-description-input"
              :state="state2(v, 'type')">
            <b-form-select class="w-100 ml-1" id="type-input" type="text"
                           v-model="v.type.$model"
                           :state="state2(v, 'type')"
                           trim>
              <b-select-option value="RET_ISR_10">{{$t('RET_ISR_10') | capitalize}}</b-select-option>
              <b-select-option value="RET_IVA">{{$t('RET_IVA') | capitalize}}</b-select-option>
              <b-select-option value="IVA_8">{{$t('IVA_8') | capitalize}}</b-select-option>
            </b-form-select>

            <b-form-invalid-feedback id="type-input-feedback">
              <div v-for="error in errors2(v, 'type')" :key="error.error">
                {{
                  $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('ORGANIZATION_ACCOUNT_TYPE_LABEL')},})  | capitalize
                }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>

          <div class="d-inline-block w-20 pl-1" style="vertical-align: middle">
            <b-button @click="removeAccount(index)" variant="danger">
              <b-icon-x></b-icon-x>
            </b-button>
          </div>
        </div>
      </b-card-text>
    </b-card>
    <!-- accounts -->

    <!-- users input -->
    <user-selector class="mt-1" v-model="$v.form.users.$model" :initial-values="this.users"></user-selector>
    <!-- users input -->
  </div>
</template>

<script>
import {required, helpers, minLength, maxLength} from "vuelidate/lib/validators";
import {Form, ProcessWithLoadingAndMessage} from "@/mixins";
import Organization from "@/organization";
import Constants from "@/constants";
import Token from "@/token";
import UserSelector from "@/users/UserSelector";

const regex = helpers.regex('regex', /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/);

export default {
  name: "OrganizationForm",
  components: {UserSelector},
  mixins: [Form, ProcessWithLoadingAndMessage],
  methods: {
    addAccount() {
      this.form.accounts.push({code: '', type: ''})
    },
    removeAccount(idx) {
      this.$v.form.accounts.$model.splice(idx, 1);
    },
    async doSave(id, data) {
      return id ? await Organization.update(id, data) : await Organization.create(data);
    },
    getData() {
      this.loadingFormData = true;
      Organization.findById(this.id)
          .then(resp => {
            console.log(resp.data);
            this.form.rfc = resp.data.rfc;
            this.form.database = resp.data.database;
            this.form.name = resp.data.name;
            this.form.accounts = resp.data.accounts;
            return Organization.getUsers(this.id);
          })
          .then(resp => {
            this.form.users = resp.data._embedded.users.map(x => x._links.self.href.replace('{?projection}', ''));
            this.users = resp.data._embedded.users;
          })
          .catch(e => {
            if (Constants.DEBUG) {
              console.error(e);
            }
            this.sendError('%loadingFormDataErrorMessage%', e);
          })
          .finally(() => {
            this.loadingFormData = false;
          });
    }
  },
  data() {
    return {
      users: [],
      loadingFormData: false,
      originals: {},
      form: {
        rfc: null, name: null, database: null, accounts: [], users: undefined
      },
      editPage: 'ORGANIZATION_EDIT_TITLE'
    }
  },
  computed: {
    isAdmin() {
      return Token.hasPermission('ORGANIZATION_CREATE_PERMISSION') || Token.hasPermission('ORGANIZATION_UPDATE_PERMISSION');
    },
  },
  validations: {
    form: {
      rfc: {
        required,
        pattern: regex
      },
      name: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(300)
      },
      database: {},
      accounts: {
        $each: {
          code: {required, minLength: minLength(4), maxLength: maxLength(60)},
          type: {required}
        }
      },
      users: {}
    }
  }
}
</script>

<style scoped>

</style>
